import * as React from "react";
import Seo from "../components/seo";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import Layout from "../components/layout";
const NotFoundPage = () => {
  return (
    <Layout>
    <main className="grid">
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/ynp0cpx.css" />
      </Helmet>
      <Seo title="404  ¯\_(ツ)_/¯" />
      <div className="photo">
        <div className="photo__background">
          <img
            src="https://korsan2000.s3.eu-central-1.amazonaws.com/00069643.jpg"
            alt="404 - strony nie znaleziono ¯\_(ツ)_/¯"
            className="photo__cover"
          />
        </div>
        <div className="photo__main">
          {" "}
          <img
            src="https://korsan2000.s3.eu-central-1.amazonaws.com/00069643.jpg"
            alt="404 - strony nie znaleziono ¯\_(ツ)_/¯"
          />
        </div>
        <h1 className="photo__title">404 - strony nie znaleziono ¯\_(ツ)_/¯</h1>
      </div>
      <div className="podcast">
        <Link to="/" className="button button--play">Ok, wracam na główną!</Link>
      </div>
    </main>
    </Layout>
  );
};

export default NotFoundPage;
